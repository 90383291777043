<template>
  <div>
    <section id="banner">
      <a href="#about" class="mouse"><span class="d-sr-only">Scroll Down</span></a>
      <template>
        <v-container>
          <h1 v-html="pageData.banner.title"></h1>
          <p v-html="pageData.banner.description"></p>
          <router-link v-html="pageData.banner.btn_text" :to="pageData.banner.btn_link" class="btn-default"></router-link>
        </v-container>
      </template>
    </section>
    <section id="about">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <img :src="pageData.about.left_img" alt="why-bison">
          </v-col>
          <v-col cols="12" sm="6">
            <h2 v-html="pageData.about.title"></h2>
            <div v-html="pageData.about.content"></div>
            <router-link v-html="pageData.about.btn_text" :to="pageData.about.btn_link" class="btn-default"></router-link>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="new-bread">
      <v-container>
        <h2 v-html="pageData.new_bread.title"></h2>
        <v-row>
          <v-col cols="12" sm="6">
            <template>
              <v-card class="mx-auto">
                <v-card-text>
                  <img :src="pageData.new_bread.broadband.image" alt="image">
                  <h3 v-html="pageData.new_bread.broadband.title"></h3>
                  <p v-html="pageData.new_bread.broadband.content"></p>
                </v-card-text>
                <router-link v-html="pageData.new_bread.broadband.btn_text" :to="pageData.new_bread.broadband.btn_link" class="btn-arrow"></router-link>
              </v-card>
            </template>
            <template>
              <v-card class="mx-auto">
                <v-card-text>
                  <img :src="pageData.new_bread.firewall.image" alt="Firewall">
                  <h3 v-html="pageData.new_bread.firewall.title"></h3>
                  <p v-html="pageData.new_bread.firewall.content"></p>
                </v-card-text>
                <router-link v-html="pageData.new_bread.broadband.btn_text" :to="pageData.new_bread.broadband.btn_link" class="btn-arrow">Learn More</router-link>
              </v-card>
            </template>
          </v-col>
          <v-col cols="12" sm="6">
            <template>
              <v-card class="mx-auto">
                <v-card-text>
                  <img :src="pageData.new_bread.carrier.image" alt="image">
                  <h3 v-html="pageData.new_bread.carrier.title"></h3>
                  <p v-html="pageData.new_bread.carrier.content"></p>
                </v-card-text>
                <router-link v-html="pageData.new_bread.carrier.btn_text" :to="pageData.new_bread.carrier.btn_link" class="btn-arrow">Learn More</router-link>
              </v-card>
            </template>
            <template>
              <v-card class="mx-auto">
                <v-card-text>
                  <img :src="pageData.new_bread.quality.image" alt="image">
                  <h3 v-html="pageData.new_bread.quality.title"></h3>
                  <p v-html="pageData.new_bread.quality.content"></p>
                </v-card-text>
                <router-link v-html="pageData.new_bread.quality.btn_text" :to="pageData.new_bread.quality.btn_link" class="btn-arrow">Learn More</router-link>
              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!--
    <section id="right-image">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <h2 v-html="pageData.right_image.title"></h2>
            <p v-html="pageData.right_image.description"></p>
            <v-row>
              <v-col cols="12" sm="6">
                <img :src="pageData.right_image.first_content.image" alt="maximize">
                <h3 v-html="pageData.right_image.first_content.title"></h3>
                <p v-html="pageData.right_image.first_content.content"></p>
              </v-col>
              <v-col cols="12" sm="6">
                <img :src="pageData.right_image.sec_content.image" alt="Cloud">
                <h3 v-html="pageData.right_image.sec_content.title"></h3>
                <p v-html="pageData.right_image.sec_content.content"></p>
              </v-col>
            </v-row>
            <router-link v-html="pageData.right_image.btn_text" :to="pageData.right_image.btn_link" class="btn-default"></router-link>
          </v-col>
          <v-col class="text-right align-self-center">
            <img :src="pageData.right_image.image" alt="Image">
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="tab-section">
      <v-container>
        <h2 v-html="pageData.tab_section.title"></h2>
        <template>
          <v-card>
            <v-tabs v-model="tab">
              <v-tab v-for="(item,index) in pageData.tab_section.items" :key="index">
                {{ item.title }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item
                  v-for="(item,index) in pageData.tab_section.items" :key="index">
                <div class="tab-content">
                  <div v-html="item.tab_content.content"></div>
                  <div class="btn-wrapper">
                    <router-link v-html="item.tab_content.btn_text" :to="item.tab_content.btn_link" class="btn-default"></router-link>
                    <router-link v-html="item.tab_content.btn_text_2" :to="item.tab_content.btn_link_2" class="btn-arrow"></router-link>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </template>
        <v-row class="list">
          <v-col cols="12" sm="4">
            <h3 v-html="pageData.tab_section.list_1.title"></h3>
            <ul>
              <li v-for="(item,index) in pageData.tab_section.list_1.items" :key="index">
                {{ item.list }}
              </li>
            </ul>
          </v-col>
          <v-col cols="12" sm="4">
            <h3 v-html="pageData.tab_section.list_2.title"></h3>
            <ul>
              <li v-for="(item,index) in pageData.tab_section.list_2.items" :key="index">
                {{ item.list }}
              </li>
            </ul>
          </v-col>
          <v-col cols="12" sm="4">
            <h3 v-html="pageData.tab_section.list_3.title"></h3>
            <ul>
              <li v-for="(item,index) in pageData.tab_section.list_3.items" :key="index">
                {{ item.list }}
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="slider">
      <v-container>
        <h2 v-html="pageData.slider.title"></h2>
      </v-container>
      <carousel :autoplay="true" :dots="false" :loop="true" :margin="30" :stagePadding="150" :autoWidth="false" :responsive="{0:{items:1,stagePadding:0},600:{items:2,stagePadding:0},1199:{items:3}}">
        <router-link :to="item.link" v-for="(item,index) in pageData.slider.items" :key="index">
          <div class="element-card">
            <img :src="item.image" alt="">
            <h3 v-html="item.title"></h3>
            <p v-html="item.content"></p>
          </div>
        </router-link>
      </carousel>
    </section>
    <section id="services">
      <v-container>
        <h2 v-html="pageData.services.title"></h2>
        <p v-html="pageData.services.description"></p>
        <v-row class="row-eq-height">
          <v-col cols="12" sm="4" v-for="(item,index) in pageData.services.items" :key="index">
            <div class="inner">
              <img :src="item.image" alt="">
              <h3 v-html="item.title"></h3>
              <p v-html="item.content"></p>
            </div>
          </v-col>
        </v-row>
        <v-card-actions>
          <router-link v-html="pageData.services.btn_text" :to="pageData.services.btn_link" class="btn-default"></router-link>
        </v-card-actions>
      </v-container>
    </section>
    <section id="amet">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="6" v-for="(item,index) in pageData.amet.items" :key="index">
                <div class="card-inner">
                  <img :src="item.image" alt="">
                  <h3 v-html="item.title"></h3>
                  <p v-html="item.content"></p>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" class="align-self-center">
            <h2 v-html="pageData.amet.title"></h2>
            <p v-html="pageData.amet.description"></p>
            <router-link v-html="pageData.amet.btn_text" :to="pageData.amet.btn_link" class="btn-default"></router-link>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="faqs">
      <v-container class="smaller">
        <h2 v-html="pageData.faqs.title"></h2>
        <p v-html="pageData.faqs.description"></p>
        <router-link v-html="pageData.faqs.btn_text" :to="pageData.faqs.btn_link" class="btn-arrow"></router-link>
        <v-row>
          <v-col md="12">
            <div class="faq-inner">
              <template>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(item,index) in pageData.faqs.items" :key="index">
                    <v-expansion-panel-header>
                      {{ item.question }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-html="item.answer"></div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    -->

    <section id="partners">
      <v-container class="smaller">
        <h2 v-html="pageData.north_america_partners.title"></h2>
        <p v-html="pageData.north_america_partners.description"></p>
        <v-row class="row-eq-height">
          <v-col cols="6" sm="4" v-for="(item,index) in pageData.north_america_partners.items" :key="index">
            <div class="img-div">
              <a :href="item.url">
                <img :src="item.image" alt="">
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="smaller">
        <h2 v-html="pageData.partners.title"></h2>
        <p v-html="pageData.partners.description"></p>
        <v-row class="row-eq-height">
          <v-col cols="6" sm="4" v-for="(item,index) in pageData.partners.items" :key="index">
            <div class="img-div">
              <a :href="item.url">
                <img :src="item.image" alt="">
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section id="logos">
      <v-container class="smaller">
        <h2 v-html="pageData.logos.title"></h2>
        <p v-html="pageData.logos.description"></p>
        <v-row class="row-eq-height">
          <v-col cols="6" sm="4" v-for="(item,index) in pageData.logos.items" :key="index">
            <div class="img-div">
              <img :src="item.image" alt="">
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="contact" data-app>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <h2 v-html="pageData.contact.title"></h2>
            <div v-html="pageData.contact.content"></div>
            <div class="email">
              <a target="_blank" v-html="pageData.contact.email" :href="'mailto:'+ pageData.contact.email"></a>
            </div>
            <!--
            <div class="phone">
              <a v-html="pageData.contact.phone" :to="'tel:'+pageData.contact.phone"></a>
            </div>
            -->
            <div class="email-2">
              <a target="_blank" v-html="pageData.contact.email_2" :href="'mailto:'+ pageData.contact.email_2"></a>
              <span>(for existing customers)</span>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="form-outer">
              <v-card ref="form">
                <v-card-text>
                  <h3>Fill in the form for more information!</h3>
                  <v-text-field v-model="form.name" placeholder="Your name" required></v-text-field>
                  <v-text-field v-model="form.email" placeholder="example@gmail.com" required></v-text-field>
                  <v-text-field v-model="form.phone" required placeholder="Phone (optional)"></v-text-field>
                  <v-autocomplete v-model="form.heard" :items="heardOptions" placeholder="How did you hear about us" required></v-autocomplete>
                  <v-textarea v-model="form.message" class="text" placeholder="Message" rows="2" row-height="20"></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-btn class="btn-default" text v-on:click="submitForm">Confirm</v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>
<script>
// import carousel from 'v-owl-carousel'
import data from '../assets/page-data.json'
export default {
  components: {
    //carousel
  },
  data: () => ({
    tab: null,
    pageData: data,
    heardOptions: [
      'Online search (Google, GitHub or elsewhere)',
      'IT news portal',
      'Advertisement',
      'Forum, mailing list or other discussion groups',
      'Blog or other article',
      'Recommendation',
      'Other (please mention in the message)',
    ],
    form: {
      name: '',
      email: '',
      phone: '',
      heard: '',
      message: ''
    }
  }),
  created: function () {
  },
  mounted: function () {
  },
  methods: {
    submitForm: async function () {
      if (!this.form.name || !this.form.email || !this.form.heard || !this.form.message) {
        alert("Please complete all required fields in the form.");
        return;
      }
      const resp = await fetch('/api/v1/contact/', {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        body: JSON.stringify(this.form)
      }).then((resp) => {
        if (resp.status >= 400 && resp.status < 600) {
          alert("Oops.. something went wrong :( Please drop us an email!");
          return null;
        }
        return resp;
      })
      if (resp) {
        await resp.text()
        alert("Thank you! We will get back to you soon.");
        this.form.name = this.form.email = this.form.heard = this.form.message = '';
      }
    }
  },
  metaInfo() {
    return {
      title: "Bison Router — a high-performance software router",
      meta: [
        {name: 'description', content: 'Bison Router is a high-performance software router for the broadband Internet Service Providers.'},
        {name: 'keywords', content: 'router, border network gateway, bng, bras, pppoe, ipoe, bgp, cg-nat, qos, dpdk, software router'},
        {property: 'og:title', content: "Bison Router — a high-performance software router"},
        {property: 'og:site_name', content: 'Bison Router — a high performance software router'},
        {property: 'og:type', content: 'website'}
      ]
    }
  }
}
</script>
